@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.gradient-symmetry {
  background-color: #147a4b;
  background-image: linear-gradient(160deg, rgba(172, 255, 216, 0.6) 60%, rgba(208, 255, 238, 0.6) 100%); }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f4f3ef00;
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(325deg, #4f57b7 0, #1c2135 0%);
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(31, 38, 135, 0); }

::-webkit-scrollbar {
  width: 0.2px;
  height: 1px; }

label {
  font-size: 12px;
  color: black;
  font-family: "Inter", "sans-serif"; }

.big-title-input > textarea {
  font-size: 24px !important;
  border-bottom: #b3b3b3; }

.MuiCalendarPicker-root {
  width: auto !important; }

.yoda-animation {
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite; }

/*Cube*/
.animated-cube {
  animation: hue-rotate 3s linear infinite; }

@keyframes hue-rotate {
  from {
    -webkit-filter: hue-rotate(0);
    -moz-filter: hue-rotate(0);
    -ms-filter: hue-rotate(0);
    filter: hue-rotate(0); }
  to {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg); } }

/* Logo*/
.otp-login-container .title-font {
  font-family: Poppins;
  font-weight: 700;
  color: #f2c0f2; }

.otp-login-container .caption-font {
  font-family: inter;
  font-size: 2em;
  font-weight: 700;
  color: white; }

.otp-login-container form {
  padding: 25px;
  margin-right: auto;
  margin-left: auto;
  max-width: 23.75rem;
  background-color: #d8f3d7;
  border-radius: 3px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  font-family: inter; }

.otp-login-container input,
.otp-login-container textarea {
  display: block;
  padding: 0.5rem 0.75rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #eee;
  border-left: 0;
  border-radius: 3px;
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1); }

.otp-login-container input:focus,
.otp-login-container textarea:focus {
  outline: 0;
  border-bottom-color: #f2c0f2; }

.otp-login-container textarea {
  resize: vertical; }

.otp-login-container .btn {
  display: inline-block;
  padding: 0.75rem 1rem;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
  min-width: 160px; }

.otp-login-container .btn:focus,
.otp-login-container .btn:hover {
  /* box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07); */
  background-color: #ed4c41 !important;
  color: #fed6d2 !important; }

.otp-login-container .btn:focus {
  outline: 0; }

.otp-login-container body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.otp-login-container .poppins {
  font-family: "Poppins", sans-serif; }

.otp-login-container .inter {
  font-family: "Inter", sans-serif; }

.purple-bg {
  background: linear-gradient(252.44deg, #4e1882 0%, #300964 100%), #9929ea;
  background-size: cover; }

.login-bg {
  background-color: #fff; }

.otp-login-container .font-purple {
  color: #4e1882; }

.otp-login-container .light-purple-bg {
  background-color: #f2c0f2; }

.border-chronos {
  border-color: rgba(221, 214, 254, 0.15) !important; }

/* Form */
.glass-form {
  background: rgba(254, 214, 210, 0.2) !important;
  box-shadow: 0 8px 32px 0 rgba(254, 214, 210, 0.35) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  -moz-backdrop-filter: blur(5px) !important;
  border-radius: 10px !important;
  border: 1px solid rgba(254, 214, 210, 0.15) !important; }

.glass-modal-header {
  background: rgba(254, 214, 210, 0.25) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  -moz-backdrop-filter: blur(5px) !important; }

/*Theme Colors*/
/*Borders*/
.top-nav {
  background: #ffe9e5;
  box-shadow: 0px 10px 20px -13px rgba(0, 0, 0, 0.15); }

.tapbar {
  background: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px -10px 20px -13px rgba(0, 0, 0, 0.15); }

.side-nav {
  background: #fed6d2;
  background: linear-gradient(133deg, #fed6d2 0%, #ffecea 100%);
  max-width: 252px;
  width: 100%; }

.menuitem {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #171718;
  padding: 22px 28px;
  border-right: 5px solid transparent; }
  .menuitem:hover {
    background: rgba(48, 23, 9, 0.1);
    color: #6d1413;
    background: #fafafa;
    border-radius: 25px 0px 0px 25px; }
  .menuitem.active {
    font-weight: 600;
    background: #fafafa;
    border-radius: 25px 0px 0px 25px;
    color: #6d1413; }

/*Theme Colors*/
/*Borders*/
/*Responsiveness*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
.priority-light-1 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: #db2b39;
  background-color: #f7d1d4; }
  .priority-light-1:hover {
    background-color: #fcf0f1;
    cursor: pointer; }

.priority-light-2 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: #ba7507;
  background-color: #fde4be; }
  .priority-light-2:hover {
    background-color: #fef6e9;
    cursor: pointer; }

.priority-light-3 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: #c44900;
  background-color: #ffd4bb; }
  .priority-light-3:hover {
    background-color: #fff1e8;
    cursor: pointer; }

.priority-light-4 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: #13293d;
  background-color: #b9d2e9; }
  .priority-light-4:hover {
    background-color: #e8f0f8;
    cursor: pointer; }

.priority-light-5 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: #0a8754;
  background-color: #b6f9de; }
  .priority-light-5:hover {
    background-color: #e7fdf4;
    cursor: pointer; }

.priority-light-6 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: #332e35;
  background-color: #f1f1f1; }
  .priority-light-6:hover {
    background-color: #f4f4f4;
    cursor: pointer; }

.priority-light-0 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: #db2b39;
  background-color: #f7d1d4; }
  .priority-light-0:hover {
    background-color: #fcf0f1;
    cursor: pointer; }

.priority-dark-1 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: white;
  background-color: #db2b39; }
  .priority-dark-1:hover {
    background-color: #af1e2a;
    cursor: pointer; }

.priority-dark-2 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: white;
  background-color: #ba7507; }
  .priority-dark-2:hover {
    background-color: #935d05;
    cursor: pointer; }

.priority-dark-3 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: white;
  background-color: #c44900; }
  .priority-dark-3:hover {
    background-color: #983800;
    cursor: pointer; }

.priority-dark-4 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: white;
  background-color: #13293d; }
  .priority-dark-4:hover {
    background-color: #0f2131;
    cursor: pointer; }

.priority-dark-5 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: white;
  background-color: #6bd425; }
  .priority-dark-5:hover {
    background-color: #086842;
    cursor: pointer; }

.priority-dark-6 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: white;
  background-color: #332e35; }
  .priority-dark-6:hover {
    background-color: #2b2b2d;
    cursor: pointer; }

.priority-dark-0 {
  border-radius: 5px;
  padding: 2px 6px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: white;
  background-color: #db2b39; }
  .priority-dark-0:hover {
    background-color: #af1e2a;
    cursor: pointer; }

/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
.status-light-NotStarted {
  padding: 0.5px 10px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: #db2b39;
  background-color: #f7d1d4; }
  .status-light-NotStarted:hover {
    background-color: #fcf0f1;
    cursor: pointer; }

.status-light-InProgress {
  padding: 0.5px 10px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: #ba7507;
  background-color: #fde4be; }
  .status-light-InProgress:hover {
    background-color: #fef6e9;
    cursor: pointer; }

.status-light-InReview {
  padding: 0.5px 10px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: #c44900;
  background-color: #ffd4bb; }
  .status-light-InReview:hover {
    background-color: #fff1e8;
    cursor: pointer; }

.status-light-Completed {
  padding: 0.5px 10px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: #0a8754;
  background-color: #b6f9de; }
  .status-light-Completed:hover {
    background-color: #e7fdf4;
    cursor: pointer; }

.status-light-Assign {
  padding: 0.5px 10px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: #db2b39;
  background-color: #f7d1d4; }
  .status-light-Assign:hover {
    background-color: #fcf0f1;
    cursor: pointer; }

.status-dark-NotStarted {
  padding: 0.5px 10px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: white;
  background-color: #db2b39; }
  .status-dark-NotStarted:hover {
    background-color: #af1e2a;
    cursor: pointer; }

.status-dark-InProgress {
  padding: 0.5px 10px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: white;
  background-color: #ba7507; }
  .status-dark-InProgress:hover {
    background-color: #935d05;
    cursor: pointer; }

.status-dark-InReview {
  padding: 0.5px 10px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: white;
  background-color: #c44900; }
  .status-dark-InReview:hover {
    background-color: #983800;
    cursor: pointer; }

.status-dark-Completed {
  padding: 0.5px 10px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: white;
  background-color: #6bd425; }
  .status-dark-Completed:hover {
    background-color: #086842;
    cursor: pointer; }

.status-dark-Assign {
  padding: 0.5px 10px;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  color: white;
  background-color: #db2b39; }
  .status-dark-Assign:hover {
    background-color: #af1e2a;
    cursor: pointer; }

.suggestions-slide-in {
  opacity: 1;
  -webkit-animation: slideFromRight 1s ease;
  -moz-animation: slideFromRight 1s ease;
  -ms-animation: slideFromRight 1s ease;
  -o-animation: slideFromRight 1s ease;
  animation: slideFromRight 1s ease; }

.suggestions-slide-out {
  opacity: 1;
  -webkit-animation: slideOutRight 1s ease;
  -moz-animation: slideOutRight 1s ease;
  -ms-animation: slideOutRight 1s ease;
  -o-animation: slideOutRight 1s ease;
  animation: slideOutRight 1s ease; }

@-webkit-keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-moz-keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-ms-keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-o-keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slideFromRight {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@-webkit-keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 0;
    transform: translateX(30px); } }

@-moz-keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 0;
    transform: translateX(30px); } }

@-ms-keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 0;
    transform: translateX(30px); } }

@-o-keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 0;
    transform: translateX(30px); } }

@keyframes slideOutRight {
  0% {
    opacity: 1;
    transform: translateX(0px); }
  50% {
    opacity: 0.5;
    transform: translateX(10px); }
  100% {
    opacity: 0;
    transform: translateX(30px); } }

.search-suggestion-box {
  height: 0px;
  max-height: 0px;
  transition: max-height 0.5s ease; }

.search-suggestion-box-expanded {
  height: auto;
  max-height: 100px;
  transition: max-height 1s ease; }

.show-learning-box {
  opacity: 1;
  -webkit-animation: reveal-down 0.5s ease;
  -moz-animation: reveal-down 0.5s ease;
  -ms-animation: reveal-down 0.5s ease;
  -o-animation: reveal-down 0.5s ease;
  animation: reveal-down 0.5s ease; }

.hide-learning-box {
  opacity: 0;
  -webkit-animation: hide2 1s ease;
  -moz-animation: hide2 1s ease;
  -ms-animation: hide2 1s ease;
  -o-animation: hide2 1s ease;
  animation: hide2 1s ease; }

.learning-box {
  width: 100%;
  transition: all 0.5s ease; }

.learning-box-details {
  min-height: 0px;
  transition: all 0.5s ease; }

.learning-box-details-expanded {
  min-height: 152px;
  transition: all 0.5s ease; }

.learning-box-expanded {
  width: 100%;
  height: auto;
  transition: all 0.5s ease; }

@-webkit-keyframes reveal-down {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@-moz-keyframes reveal-down {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@-ms-keyframes reveal-down {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@-o-keyframes reveal-down {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes reveal-down {
  0% {
    opacity: 0;
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@-webkit-keyframes hide2 {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(10px); } }

@-moz-keyframes hide2 {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(10px); } }

@-ms-keyframes hide2 {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(10px); } }

@-o-keyframes hide2 {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(10px); } }

@keyframes hide2 {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(10px); } }

/*Theme Colors*/
/*Borders*/
.release-card {
  padding: 10px 20px;
  border-radius: 20px; }
  .release-card.completed {
    background-color: #ebf8ec; }
    .release-card.completed h1 {
      color: #171718;
      font-weight: 700;
      font-family: "Inter", sans-serif;
      font-size: 14px; }
    .release-card.completed div {
      color: #373739;
      font-weight: 400;
      font-family: "Inter", sans-serif;
      font-size: 10px; }
  .release-card.upcoming {
    background-color: #ed4c41; }
    .release-card.upcoming h1 {
      color: white;
      font-weight: 700;
      font-family: "Inter", sans-serif;
      font-size: 14px; }
    .release-card.upcoming div {
      color: #fcf0f1;
      font-weight: 400;
      font-family: "Inter", sans-serif;
      font-size: 10px; }
  .release-card.failed {
    background-color: #ffe2df; }
    .release-card.failed h1 {
      color: #171718;
      font-weight: 700;
      font-family: "Inter", sans-serif;
      font-size: 14px; }
    .release-card.failed div {
      color: #373739;
      font-weight: 400;
      font-family: "Inter", sans-serif;
      font-size: 10px; }

/*Theme Colors*/
/*Borders*/
.dsr-screen-bg-yellow {
  background: radial-gradient(165.57% 78.81% at 0% 95.19%, #fb6f60 0%, #fcac19 52.4%, #f7de3b 100%); }

.dsr-screen-bg-red {
  background: linear-gradient(144.1deg, #ff371c 7.74%, #ffb58c 93.45%); }

.dsr-screen-bg-pink {
  background: linear-gradient(136.18deg, #ff44a9 -16.05%, #ffe486 82.52%); }

.dsr-screen-bg-blue {
  background: linear-gradient(170.95deg, #0085ff -11.13%, #86f1ff 60.86%); }

.dsr-screen-bg-blue-black {
  background: radial-gradient(127.3% 87% at 26.94% 19.37%, #07070f 4.98%, #232968 31.34%, #4f57b7 51.22%, #6775f5 66.64%, #4858e7 71.34%, #2630ad 77.6%, #07070f 92.72%); }

.dsr-stat-card {
  background: #ffffff;
  /* Neutral/100 */
  border: 1px solid #f3f2f2;
  /* Shadow-md */
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px; }
  .dsr-stat-card .count {
    letter-spacing: 0.025em;
    background: linear-gradient(180deg, #f2ea8c 3.44%, #fba804 46.15%, #e72113 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 64px;
    line-height: 72px; }
  .dsr-stat-card .stat-name {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.025em;
    color: #323232; }

.dsr-issue-card {
  background: #ffffff;
  /* Neutral/100 */
  border: 1px solid #f3f2f2;
  /* Shadow-md */
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px; }
  .dsr-issue-card .count {
    letter-spacing: 0.025em;
    color: #323232;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px; }
  .dsr-issue-card .issue-name {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.025em;
    color: #323232; }
  .dsr-issue-card .issue-description {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #323232; }

.dsr-task-card {
  background: #ffffff;
  /* Neutral/100 */
  border: 1px solid #f3f2f2;
  /* Shadow-md */
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px; }
  .dsr-task-card div .task-title {
    color: #323232;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: 0.025em; }
  .dsr-task-card div .task-priority {
    border-radius: 9999px;
    padding: 1px 8px;
    color: #fff;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em; }
    .dsr-task-card div .task-priority.priority-1 {
      background-color: #db2b39; }
    .dsr-task-card div .task-priority.priority-2 {
      background-color: #f7cd16; }
    .dsr-task-card div .task-priority.priority-3 {
      background-color: #ff9000; }
    .dsr-task-card div .task-priority.priority-4 {
      background-color: #13293d; }
    .dsr-task-card div .task-priority.priority-5 {
      background-color: #0a8754; }
    .dsr-task-card div .task-priority.priority-6 {
      background-color: #a4a2a5; }
  .dsr-task-card .divider {
    background: #e7e6e5;
    border-radius: 8px;
    height: 2px; }
  .dsr-task-card .task-date {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */
    letter-spacing: 0.025em;
    color: #323232; }
  .dsr-task-card .due {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    letter-spacing: 0.025em; }
    .dsr-task-card .due.red {
      color: #e72113; }
    .dsr-task-card .due.yellow {
      color: #fba804; }
    .dsr-task-card .due.green {
      color: #178229; }
  .dsr-task-card .task-status .label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */
    letter-spacing: 0.025em;
    color: #323232; }
  .dsr-task-card .task-status .status {
    border-radius: 8px;
    padding: 8px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */
    letter-spacing: 0.025em; }
    .dsr-task-card .task-status .status.status-NotStarted {
      background-color: #feb8b1;
      color: #6d1413; }
    .dsr-task-card .task-status .status.status-InProgress {
      background-color: #ffe99a;
      color: #532a01; }
    .dsr-task-card .task-status .status.status-InReview {
      background-color: #fec5a4;
      color: #601800; }
    .dsr-task-card .task-status .status.status-Completed {
      background-color: #c2f2bd;
      color: #08422f; }
  .dsr-task-card .person-label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    letter-spacing: 0.025em;
    color: #323232; }
  .dsr-task-card .person-tag {
    border: 1px solid #e7e6e5;
    border-radius: 24px;
    padding: 8px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */
    letter-spacing: 0.025em;
    color: #323232; }

.dsr-task-wrapper-card {
  background: #fafafa;
  border-radius: 24px;
  padding: 16px; }
  .dsr-task-wrapper-card .info {
    color: #323232;
    font-family: "Inter", sans-serif;
    font-style: normal; }
    .dsr-task-wrapper-card .info h1 {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
      letter-spacing: 0.025em; }
    .dsr-task-wrapper-card .info p {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 114% */
      letter-spacing: 0.025em; }

.dsr-project-card {
  background: #ffffff;
  /* Neutral/100 */
  border: 1px solid #f3f2f2;
  /* Shadow-md */
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px; }
  .dsr-project-card div .project-title {
    color: #323232;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: 0.025em; }
  .dsr-project-card .divider {
    background: #e7e6e5;
    border-radius: 8px;
    height: 2px; }
  .dsr-project-card .stat {
    background: #ffffff;
    /* Neutral/100 */
    border: 1px solid #f3f2f2;
    /* Shadow-md */
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px 8px; }
    .dsr-project-card .stat .number {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      /* identical to box height, or 125% */
      letter-spacing: 0.025em; }
    .dsr-project-card .stat .labels {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      /* or 160% */
      letter-spacing: 0.025em;
      color: #323232; }
    .dsr-project-card .stat .red {
      color: #e72113; }
    .dsr-project-card .stat .yellow {
      color: #fbc920; }
    .dsr-project-card .stat .green {
      color: #33a329; }
    .dsr-project-card .stat .gray {
      color: #696763; }
    .dsr-project-card .stat .purple {
      color: #8f1dc9; }
  .dsr-project-card .person-label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    letter-spacing: 0.025em;
    color: #323232; }
  .dsr-project-card .person-tag {
    border: 1px solid #e7e6e5;
    border-radius: 24px;
    padding: 8px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */
    letter-spacing: 0.025em;
    color: #323232; }

.dsr-user-card {
  background: #ffffff;
  /* Neutral/100 */
  border: 1px solid #f3f2f2;
  /* Shadow-md */
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px; }
  .dsr-user-card div .user-title {
    color: #323232;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: 0.025em; }
  .dsr-user-card div .user-subtitle {
    color: #323232;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: 0.025em; }
  .dsr-user-card .divider {
    background: #e7e6e5;
    border-radius: 8px;
    height: 2px; }
  .dsr-user-card .stat {
    background: #ffffff;
    /* Neutral/100 */
    border: 1px solid #f3f2f2;
    /* Shadow-md */
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px 8px; }
    .dsr-user-card .stat .number {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      /* identical to box height, or 125% */
      letter-spacing: 0.025em; }
    .dsr-user-card .stat .labels {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      /* or 160% */
      letter-spacing: 0.025em;
      color: #323232; }
    .dsr-user-card .stat .red {
      color: #e72113; }
    .dsr-user-card .stat .yellow {
      color: #fbc920; }
    .dsr-user-card .stat .green {
      color: #33a329; }
    .dsr-user-card .stat .gray {
      color: #696763; }
    .dsr-user-card .stat .purple {
      color: #8f1dc9; }
  .dsr-user-card .person-label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    letter-spacing: 0.025em;
    color: #323232; }
  .dsr-user-card .person-tag {
    border: 1px solid #e7e6e5;
    border-radius: 24px;
    padding: 8px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    /* identical to box height, or 160% */
    letter-spacing: 0.025em;
    color: #323232; }

.dsr-project-goal-card {
  background: linear-gradient(180deg, rgba(35, 41, 104, 0.5) 0%, rgba(35, 41, 104, 0.2) 100%);
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;
  padding: 24px 8px; }
  .dsr-project-goal-card div .project-title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    /* Shades/50 */
    color: #ffffff; }
  .dsr-project-goal-card .goal {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* Shades/50 */
    color: #ffffff; }
    .dsr-project-goal-card .goal .status {
      padding: 4px 8px;
      border-radius: 4px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 8px;
      line-height: 10px; }
      .dsr-project-goal-card .goal .status.NotStarted {
        color: #e72113;
        background: #fcd6cf;
        /* Error/100 */
        border: 0.5px solid #feb8b1;
        box-shadow: 0px 0px 5px #fcd6cf; }
      .dsr-project-goal-card .goal .status.InProgress {
        color: #ba7507;
        background: #f7f4d4;
        /* Accent Secondary/300 */
        border: 0.5px solid #f7de3b;
        box-shadow: 0px 0px 5px #f2ea8c; }
      .dsr-project-goal-card .goal .status.InReview {
        color: #1a6be5;
        background: #e7f9fd;
        /* Accent Primary/100 */
        border: 0.5px solid #c2e8f0;
        box-shadow: 0px 0px 5px #e7f9fd; }
      .dsr-project-goal-card .goal .status.Completed {
        color: #33a329;
        background: #edfddd;
        /* Succes/300 */
        border: 0.5px solid #abe085;
        box-shadow: 0px 0px 5px #abe085; }

.talentCard {
  background: #fafafa;
  /* Neutral/500 */
  border: 1px solid #696763;
  /* Shadow */
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 16px; }

.rbc-calendar {
  min-height: 580px; }

/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Responsiveness*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/* Main Theme Colors*/
.font-indigo-10 {
  color: #f6f8ff; }

.font-indigo-50 {
  color: #ebedf7; }

.font-indigo-100 {
  color: #f0f1f8; }

.font-indigo-200 {
  color: #e8ebff; }

.font-indigo-250 {
  color: #a4abcb; }

.font-indigo-300 {
  color: #8da1fc; }

.font-indigo-400 {
  color: #6775f5; }

.font-indigo-550 {
  color: #4f57b7; }

.font-indigo-650 {
  color: #1e2447; }

.font-indigo-700 {
  color: #384486; }

.bg-indigo-10 {
  background-color: #f6f8ff; }

.bg-indigo-50 {
  background-color: #ebedf7; }

.bg-indigo-100 {
  background-color: #f0f1f8; }

.bg-indigo-200 {
  background-color: #e8ebff; }

.bg-indigo-250 {
  background-color: #a4abcb; }

.bg-indigo-300 {
  background-color: #8da1fc; }

.bg-indigo-400 {
  background-color: #6775f5; }

.bg-indigo-550 {
  background-color: #4f57b7; }

.bg-indigo-650 {
  background-color: #1e2447; }

.bg-indigo-700 {
  background-color: #384486; }

.border-indigo-10 {
  border-color: #f6f8ff; }

.border-indigo-50 {
  border-color: #ebedf7; }

.border-indigo-100 {
  border-color: #f0f1f8; }

.border-indigo-200 {
  border-color: #e8ebff; }

.border-indigo-250 {
  border-color: #a4abcb; }

.border-indigo-300 {
  border-color: #8da1fc; }

.border-indigo-400 {
  border-color: #6775f5; }

.border-indigo-550 {
  border-color: #4f57b7; }

.border-indigo-650 {
  border-color: #1e2447; }

.border-indigo-700 {
  border-color: #384486; }

.font-gray-50 {
  color: #f8f8f9; }

.font-gray-60 {
  color: #f1f1f1; }

.font-gray-100 {
  color: #f4f4f4; }

.font-gray-150 {
  color: #e1e1e1; }

.font-gray-200 {
  color: #d8d8d8; }

.font-gray-250 {
  color: #a4a2a5; }

.font-gray-300 {
  color: #6d6d6d; }

.font-gray-350 {
  color: #747474; }

.font-gray-400 {
  color: #424242; }

.font-gray-500 {
  color: #332e35; }

.font-gray-600 {
  color: #323232; }

.font-gray-650 {
  color: #373739; }

.font-gray-900 {
  color: #2b2b2d; }

.font-gray-1000 {
  color: #171718; }

.bg-gray-50 {
  background-color: #f8f8f9; }

.bg-gray-60 {
  background-color: #f1f1f1; }

.bg-gray-100 {
  background-color: #f4f4f4; }

.bg-gray-150 {
  background-color: #e1e1e1; }

.bg-gray-200 {
  background-color: #d8d8d8; }

.bg-gray-250 {
  background-color: #a4a2a5; }

.bg-gray-300 {
  background-color: #6d6d6d; }

.bg-gray-350 {
  background-color: #747474; }

.bg-gray-400 {
  background-color: #424242; }

.bg-gray-500 {
  background-color: #332e35; }

.bg-gray-600 {
  background-color: #323232; }

.bg-gray-650 {
  background-color: #373739; }

.bg-gray-900 {
  background-color: #2b2b2d; }

.bg-gray-1000 {
  background-color: #171718; }

.border-gray-50 {
  border-color: #f8f8f9; }

.border-gray-60 {
  border-color: #f1f1f1; }

.border-gray-100 {
  border-color: #f4f4f4; }

.border-gray-150 {
  border-color: #e1e1e1; }

.border-gray-200 {
  border-color: #d8d8d8; }

.border-gray-250 {
  border-color: #a4a2a5; }

.border-gray-300 {
  border-color: #6d6d6d; }

.border-gray-350 {
  border-color: #747474; }

.border-gray-400 {
  border-color: #424242; }

.border-gray-500 {
  border-color: #332e35; }

.border-gray-600 {
  border-color: #323232; }

.border-gray-650 {
  border-color: #373739; }

.border-gray-900 {
  border-color: #2b2b2d; }

.border-gray-1000 {
  border-color: #171718; }

.font-yellow-50 {
  color: #fef6e9; }

.font-yellow-100 {
  color: #fde4be; }

.font-yellow-500 {
  color: #f7cd16; }

.font-yellow-550 {
  color: #f1c21b; }

.font-yellow-600 {
  color: #e0b808; }

.font-yellow-700 {
  color: #6b5f1c; }

.font-yellow-750 {
  color: #5b511b; }

.font-yellow-800 {
  color: #ba7507; }

.font-yellow-850 {
  color: #935d05; }

.font-yellow-900 {
  color: #532a01; }

.font-yellow-1000 {
  color: #301709; }

.bg-yellow-50 {
  background-color: #fef6e9; }

.bg-yellow-100 {
  background-color: #fde4be; }

.bg-yellow-500 {
  background-color: #f7cd16; }

.bg-yellow-550 {
  background-color: #f1c21b; }

.bg-yellow-600 {
  background-color: #e0b808; }

.bg-yellow-700 {
  background-color: #6b5f1c; }

.bg-yellow-750 {
  background-color: #5b511b; }

.bg-yellow-800 {
  background-color: #ba7507; }

.bg-yellow-850 {
  background-color: #935d05; }

.bg-yellow-900 {
  background-color: #532a01; }

.bg-yellow-1000 {
  background-color: #301709; }

.border-yellow-50 {
  border-color: #fef6e9; }

.border-yellow-100 {
  border-color: #fde4be; }

.border-yellow-500 {
  border-color: #f7cd16; }

.border-yellow-550 {
  border-color: #f1c21b; }

.border-yellow-600 {
  border-color: #e0b808; }

.border-yellow-700 {
  border-color: #6b5f1c; }

.border-yellow-750 {
  border-color: #5b511b; }

.border-yellow-800 {
  border-color: #ba7507; }

.border-yellow-850 {
  border-color: #935d05; }

.border-yellow-900 {
  border-color: #532a01; }

.border-yellow-1000 {
  border-color: #301709; }

.font-orange-50 {
  color: #fff1e8; }

.font-orange-100 {
  color: #ffd4bb; }

.font-orange-500 {
  color: #ff9000; }

.font-orange-600 {
  color: #db7c00; }

.font-orange-700 {
  color: #c44900; }

.font-orange-800 {
  color: #983800; }

.bg-orange-50 {
  background-color: #fff1e8; }

.bg-orange-100 {
  background-color: #ffd4bb; }

.bg-orange-500 {
  background-color: #ff9000; }

.bg-orange-600 {
  background-color: #db7c00; }

.bg-orange-700 {
  background-color: #c44900; }

.bg-orange-800 {
  background-color: #983800; }

.border-orange-50 {
  border-color: #fff1e8; }

.border-orange-100 {
  border-color: #ffd4bb; }

.border-orange-500 {
  border-color: #ff9000; }

.border-orange-600 {
  border-color: #db7c00; }

.border-orange-700 {
  border-color: #c44900; }

.border-orange-800 {
  border-color: #983800; }

.font-green-20 {
  color: #ebf8ec; }

.font-green-50 {
  color: #e7fdf4; }

.font-green-100 {
  color: #b6f9de; }

.font-green-500 {
  color: #0a8754; }

.font-green-550 {
  color: #6bd425; }

.font-green-600 {
  color: #086842; }

.bg-green-20 {
  background-color: #ebf8ec; }

.bg-green-50 {
  background-color: #e7fdf4; }

.bg-green-100 {
  background-color: #b6f9de; }

.bg-green-500 {
  background-color: #0a8754; }

.bg-green-550 {
  background-color: #6bd425; }

.bg-green-600 {
  background-color: #086842; }

.border-green-20 {
  border-color: #ebf8ec; }

.border-green-50 {
  border-color: #e7fdf4; }

.border-green-100 {
  border-color: #b6f9de; }

.border-green-500 {
  border-color: #0a8754; }

.border-green-550 {
  border-color: #6bd425; }

.border-green-600 {
  border-color: #086842; }

.font-blue-50 {
  color: #e8f0f8; }

.font-blue-100 {
  color: #b9d2e9; }

.font-blue-550 {
  color: #3778c6; }

.font-blue-700 {
  color: #13293d; }

.font-blue-800 {
  color: #0f2131; }

.bg-blue-50 {
  background-color: #e8f0f8; }

.bg-blue-100 {
  background-color: #b9d2e9; }

.bg-blue-550 {
  background-color: #3778c6; }

.bg-blue-700 {
  background-color: #13293d; }

.bg-blue-800 {
  background-color: #0f2131; }

.border-blue-50 {
  border-color: #e8f0f8; }

.border-blue-100 {
  border-color: #b9d2e9; }

.border-blue-550 {
  border-color: #3778c6; }

.border-blue-700 {
  border-color: #13293d; }

.border-blue-800 {
  border-color: #0f2131; }

.font-purple-100 {
  color: #f1eaf6; }

.font-purple-550 {
  color: #a346ce; }

.font-purple-600 {
  color: #4b355f; }

.font-purple-700 {
  color: #341e48; }

.bg-purple-100 {
  background-color: #f1eaf6; }

.bg-purple-550 {
  background-color: #a346ce; }

.bg-purple-600 {
  background-color: #4b355f; }

.bg-purple-700 {
  background-color: #341e48; }

.border-purple-100 {
  border-color: #f1eaf6; }

.border-purple-550 {
  border-color: #a346ce; }

.border-purple-600 {
  border-color: #4b355f; }

.border-purple-700 {
  border-color: #341e48; }

.font-red-100 {
  color: #fcf0f1; }

.font-red-150 {
  color: #ffe2df; }

.font-red-200 {
  color: #f7d1d4; }

.font-red-500 {
  color: #ed4c41; }

.font-red-550 {
  color: #db2b39; }

.font-red-600 {
  color: #c61e29; }

.font-red-700 {
  color: #af1e2a; }

.font-red-1000 {
  color: #6d1413; }

.bg-red-100 {
  background-color: #fcf0f1; }

.bg-red-150 {
  background-color: #ffe2df; }

.bg-red-200 {
  background-color: #f7d1d4; }

.bg-red-500 {
  background-color: #ed4c41; }

.bg-red-550 {
  background-color: #db2b39; }

.bg-red-600 {
  background-color: #c61e29; }

.bg-red-700 {
  background-color: #af1e2a; }

.bg-red-1000 {
  background-color: #6d1413; }

.border-red-100 {
  border-color: #fcf0f1; }

.border-red-150 {
  border-color: #ffe2df; }

.border-red-200 {
  border-color: #f7d1d4; }

.border-red-500 {
  border-color: #ed4c41; }

.border-red-550 {
  border-color: #db2b39; }

.border-red-600 {
  border-color: #c61e29; }

.border-red-700 {
  border-color: #af1e2a; }

.border-red-1000 {
  border-color: #6d1413; }

/*Theme Colors*/
/*Borders*/
.primary-yellow-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #323232;
  background: #f1c21b;
  box-shadow: 0px 10px 20px -10px rgba(241, 194, 27, 0.5);
  border-radius: 4px; }
  .primary-yellow-cta:disabled {
    color: #e1e1e1; }
  .primary-yellow-cta:disabled {
    background: #f4f4f4;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.5); }

.primary-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  border-radius: 8px;
  background: linear-gradient(180deg, #ff7e6e 0%, #c63434 100%);
  /* Shadow-lg */
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1); }
  .primary-cta:disabled {
    color: #e1e1e1; }
  .primary-cta:hover {
    background-color: #c61e29; }
  .primary-cta:active {
    background-color: #db2b39; }
  .primary-cta:disabled {
    background: #f4f4f4;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.5); }

.primary-purple-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  background: #341e48;
  box-shadow: 0px 10px 20px -10px rgba(52, 30, 72, 0.5);
  border-radius: 4px; }
  .primary-purple-cta:disabled {
    color: #fff; }
  .primary-purple-cta:hover {
    background: #4b355f; }
  .primary-purple-cta:active {
    background: #a346ce; }
  .primary-purple-cta:disabled {
    background: #e1e1e1;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.25); }

.primary-modal-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  background: #341e48;
  box-shadow: 0px 10px 20px -10px rgba(52, 30, 72, 0.5);
  border-radius: 10px;
  padding: 4px 20px !important; }
  .primary-modal-cta:disabled {
    color: #e1e1e1; }
  .primary-modal-cta:hover {
    background: #4b355f; }
  .primary-modal-cta:active {
    background: #a346ce; }
  .primary-modal-cta:disabled {
    background: #e1e1e1;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.25); }

.tertiary-purple-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #341e48; }
  .tertiary-purple-cta:disabled {
    color: #e1e1e1; }
  .tertiary-purple-cta:hover {
    text-decoration: underline; }
  .tertiary-purple-cta:active {
    text-decoration: underline;
    color: #a346ce; }

.tertiary-red-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #db2b39; }
  .tertiary-red-cta:disabled {
    color: #e1e1e1; }
  .tertiary-red-cta:hover {
    text-decoration: underline; }
  .tertiary-red-cta:active {
    text-decoration: underline;
    color: #a346ce; }

.secondary-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #6d1413;
  border-radius: 4px;
  border: 2px solid #6d1413;
  color: #6d1413;
  font-weight: 600; }
  .secondary-cta:disabled {
    color: #e1e1e1; }
  .secondary-cta:hover {
    color: #c61e29;
    border: 3px solid #c61e29; }
  .secondary-cta:active {
    border: 2px solid #db2b39;
    color: #db2b39; }
  .secondary-cta:disabled {
    border: 2px solid #e1e1e1;
    background: #fff;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.25); }

.secondary-modal-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #747474;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 2px solid #747474;
  color: #747474;
  font-weight: bold;
  padding: 4px 20px !important; }
  .secondary-modal-cta:disabled {
    color: #e1e1e1; }
  .secondary-modal-cta:hover {
    color: #323232;
    border: 3px solid #323232;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.11); }
  .secondary-modal-cta:active {
    border: 2px solid #747474;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.11);
    color: #747474; }
  .secondary-modal-cta:disabled {
    border: 1px solid #f4f4f4;
    background: #f4f4f4;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.07); }

.floating-primary-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  background: #341e48;
  box-shadow: 0px 10px 20px -10px rgba(73, 81, 151, 0.5);
  border-radius: 4px; }
  .floating-primary-cta:disabled {
    color: #e1e1e1; }
  .floating-primary-cta:hover {
    font-size: 15px;
    line-height: 22px; }
  .floating-primary-cta:active {
    border: 2px solid #341e48;
    font-size: 15px;
    line-height: 22px; }
  .floating-primary-cta:disabled {
    border: 1px solid #f4f4f4;
    background: #f4f4f4;
    box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.25); }

.floating-secondary-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #747474;
  background: #f1f1f1;
  box-shadow: 0px 0px 16px 3px rgba(50, 50, 50, 0.5);
  border-radius: 4px;
  border: 1px solid #747474; }
  .floating-secondary-cta:disabled {
    color: #e1e1e1; }
  .floating-secondary-cta:hover {
    font-weight: 700;
    border: 2px solid #747474;
    font-size: 15px;
    line-height: 22px; }
  .floating-secondary-cta:active {
    border: 2px solid #323232;
    color: #323232;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px; }
  .floating-secondary-cta:disabled {
    border: 1px solid #f4f4f4;
    background: #f4f4f4; }

.embed-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #341e48;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  border: 1px solid #341e48; }
  .embed-cta:disabled {
    color: #e1e1e1; }
  .embed-cta:hover, .embed-cta:active {
    border: 2px solid #341e48;
    font-size: 11px;
    line-height: 16px;
    color: #341e48; }
  .embed-cta:disabled {
    border: 1px solid #f4f4f4;
    background: #f4f4f4; }

/*Theme Colors*/
/*Borders*/
.modals-component {
  background: #f8f8f9;
  mix-blend-mode: normal;
  box-shadow: 0px -10px 20px -13px rgba(0, 0, 0, 0.15);
  border-radius: 20px 20px 0px 0px; }

.input-field-box {
  background: #ffe2df;
  mix-blend-mode: normal;
  border-radius: 4px;
  color: #6d1413; }

.input-field-box-selected {
  background: #6bd425;
  mix-blend-mode: normal;
  border-radius: 4px;
  color: #fff; }

.shadow-container {
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.1); }

.objectiveModal > div {
  background-color: rgba(0, 0, 0, 0.6) !important; }

/*Theme Colors*/
/*Borders*/
.input-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 18px;
  color: #000;
  margin-bottom: 10px; }
  @media (min-width: 1024px) {
    .input-label {
      font-size: 12px; } }

label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 18px;
  color: #000; }
  @media (min-width: 1024px) {
    label {
      font-size: 11px; } }

input:focus,
textarea:focus,
button:focus {
  outline-style: none; }

.theme-input {
  font-family: "Inter", sans-serif;
  /* Body */
  font-size: 11px;
  background: #fff;
  /* neutral-200 */
  border-bottom-width: 1px;
  border-radius: 8px;
  border: 1px solid #e7e6e5;
  outline: none;
  /* hover */
  /* focused */
  /* disabled */ }
  .theme-input:hover {
    color: #171a1fff;
    /* neutral-900 */
    background: #f3f4f6ff;
    /* neutral-200 */ }
  .theme-input:focused {
    color: #171a1fff;
    /* neutral-900 */
    background: #ffffffff;
    /* white */ }
  .theme-input:disabled {
    color: #171a1fff;
    /* neutral-900 */
    background: #f3f4f6ff;
    /* neutral-200 */
    border-width: 0px; }
